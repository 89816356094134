import { render, staticRenderFns } from "./products.vue?vue&type=template&id=81fdd506"
import script from "./products.vue?vue&type=script&lang=js"
export * from "./products.vue?vue&type=script&lang=js"
import style0 from "./products.vue?vue&type=style&index=0&id=81fdd506&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductList: require('/opt/hkok.ru/src/components/product/list.vue').default,BaseTabs: require('/opt/hkok.ru/src/components/base/tabs.vue').default,BaseSection: require('/opt/hkok.ru/src/components/base/section.vue').default})
