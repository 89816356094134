export default [
  {
    logo: '/sponsors/AKS.webp',
    name: 'IT Cargo Systems',
    url: 'https://itcargosystems.ru',
  },
  {
    logo: '/sponsors/STK.webp',
    name: 'STK',
    url: 'https://strans.ru',
  },
  {
    logo: '/sponsors/UTG.webp',
    name: 'UTG Express',
    url: 'https://utg-express.ru',
  },
  // {
  //   logo: '/sponsors/NordStar.webp',
  //   name: 'Nord Star',
  // },
  // {
  //   logo: '/sponsors/S7Airlines.webp',
  //   name: 's7 airlines',
  // },
  // {
  //   logo: '/sponsors/RedWings.webp',
  //   name: 'Red wings',
  // },
  // {
  //   logo: '/sponsors/UralAirline.webp',
  //   name: 'Ural Airline',
  // },
];
