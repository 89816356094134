
export default {
  name: 'base-infinity-slidebar',
  props: {
    items: {
      type: Array,
      required: true,
    },
    loops: {
      type: Array,
      default: () => [1, 2],
    },
    duration: {
      type: Number,
      default: 5,
    },
    direction: {
      type: String,
      default: 'normal',
    },
    delay: {
      type: Number,
      default: 0,
    },
    gap: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      isMounted: false,
    };
  },
  computed: {
    animationSetup () {
      return {
        animationDuration: `${this.duration}s`,
        animationDirection: `${this.direction}`,
        animationDelay: `${this.delay}s`,
      };
    },
    itemsGap () {
      if (!this.gap) return {};

      return {
        gap: `${this.gap}px`,
        padding: `0 ${this.gap / 2}px`,
      };
    },
    classes () {
      return {
      };
    },
  },
  mounted () {
    // this.copyItems();
  },
  methods: {
    // copyItems () {
    //   const copyItems = this.$refs.items.cloneNode(true);
    //   this.$refs.itemsWrap.appendChild(copyItems);
    //   this.$forceNextTick(() => this.isMounted = true);
    // },
  },
};
