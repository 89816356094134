
import sponsors from '~/constants/sponsors';

export default {
  name: 'sponsor-carousel',
  data () {
    return {
      sponsors,
      duration: 60,
      gap: 25,
    };
  },
};
