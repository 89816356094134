
import { mapActions } from 'vuex';
import Request from '~/api/request/feedback';

export default {
  name: 'section-contact-us',
  data () {
    return {
      request: new Request(),
      pending: false,
    };
  },
  methods: {
    ...mapActions(['sendFeedback']),
    sendContactUs () {
      this.pending = true;
      this.sendFeedback(this.request.build())
        .then(() => {
          this.showNotification('success', this.$t('base.feedbackSuccess'));
          this.resetRequest();
        })
        .catch(e => {
          this.showNotification('error', e.message || this.$t('errors.otherError'));
        })
        .finally(() => this.pending = false);
    },
    resetRequest () {
      this.request = new Request();
    },
    showNotification (theme, text) {
      this.$bus.$emit('add-notification', { theme, text });
    },
  },
};
