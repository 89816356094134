import { render, staticRenderFns } from "./tabs.vue?vue&type=template&id=0746ddaf"
import script from "./tabs.vue?vue&type=script&lang=js"
export * from "./tabs.vue?vue&type=script&lang=js"
import style0 from "./tabs.vue?vue&type=style&index=0&id=0746ddaf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzTabs: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/tabs/index.vue').default})
