
export default {
  name: 'section-products',
  props: {
    tabs: { type: Array, default: () => [] },
    products: { type: Array, default: () => [] },
    defaultTab: { type: String, default: '' },
    cardsWithoutBg: { type: Boolean, default: false },
    isHtml: { type: Boolean, default: false },
    isShowImage: { type: Boolean, default: false },
    mainBg: { type: Boolean, default: false },
    greyBg: { type: Boolean, default: false },
    secondBg: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    headerPadding: { type: Boolean, default: false },
    withoutPadding: { type: Boolean, default: false },
  },
};
