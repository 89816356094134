import { render, staticRenderFns } from "./index.vue?vue&type=template&id=60e8587c"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=60e8587c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionMain: require('/opt/hkok.ru/src/components/section/main/index.vue').default,SectionProducts: require('/opt/hkok.ru/src/components/section/products.vue').default,SuluContent: require('/opt/hkok.ru/node_modules/razlet-sulu-sdk/src/components/content.vue').default,BaseSection: require('/opt/hkok.ru/src/components/base/section.vue').default,SectionMainSponsor: require('/opt/hkok.ru/src/components/section/main/sponsor.vue').default,AdvantageList: require('/opt/hkok.ru/src/components/advantage/list.vue').default,SectionContactUs: require('/opt/hkok.ru/src/components/section/contact-us.vue').default})
