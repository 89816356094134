
export default {
  name: 'section-main',
  props: {
    title: { type: String, required: true },
  },
  computed: {
    titleLastWord () {
      const lastIndex = this.title.lastIndexOf(' ');
      return this.title.slice(lastIndex);
    },
    titleFirstPart () {
      const lastIndex = this.title.lastIndexOf(' ');
      return this.title.slice(0, lastIndex);
    },
  },
  methods: {
    scrollToNextSection () {
      const elHeight = this.$el.offsetHeight;
      this.$forceNextTick(() => {
        window.scrollTo({ top: elHeight, behavior: 'smooth' });
      });
    },
  },
};
