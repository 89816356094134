
export default {
  name: 'product-card',
  props: {
    product: { type: Object, default: () => ({}) },
    withoutBg: { type: Boolean, default: false },
    isHtml: { type: Boolean, default: false },
    isShowImage: { type: Boolean, default: false },
  },
  data () {
    return {
      isImageModal: false,
    };
  },
  computed: {
    classes () {
      return {
        'product-card--image': !!this.product.media,
        'product-card--icon': !!this.product.icon,
        'product-card--disabled': this.product.disabled,
        'product-card--without-bg': this.withoutBg,
        'product-card--show-image': this.isShowImage,
      };
    },
    component () {
      return this.isShowImage
        ? 'div'
        : 'nuxt-link';
    },
  },
  methods: {
    onClick () {
      if (!this.isShowImage) return;
      this.isImageModal = true;
    },
  },
};
