
import sulu from 'razlet-sulu-sdk/lib/mixins/sulu';

const localeKey = 'main';

export default {
  name: 'index-page',
  layout: 'index',
  mixins: [sulu],
  computed: {
    products () {
      return this.formatLocaleObjects([
        {
          value: 'airline',
          url: { path: '/products', query: { tab: 'airline' } },
          icon: 'icon-airplane-realistic',
        },
        {
          value: 'airport',
          url: { path: '/products', query: { tab: 'airport' } },
          icon: 'icon-check-in',
          disabled: true,
        },
        {
          value: 'agent',
          url: { path: '/products', query: { tab: 'agent' } },
          icon: 'icon-passport',
          disabled: true,
        },
      ], localeKey, 'products');
    },
    advantages () {
      return this.formatLocaleObjects([
        { value: 'tonsPerYear', icon: 'icon-shipment' },
        { value: 'passengerWeight', icon: 'icon-passanger-stay' },
        { value: 'passengerPerYear', icon: 'icon-passanger-walk' },
        { value: 'flightsPerYear', icon: 'icon-airplane' },
      ], 'advantage');
    },
  },
};
