
import { required } from 'vuelidate/lib/validators';

const localeKey = 'contactUs';

export default {
  name: 'contact-form',
  props: {
    request: { type: Object, default: () => ({}) },
    pending: { type: Boolean, default: false },
  },
  data () {
    return {
      localeKey,
    };
  },
  validations () {
    return {
      request: {
        name: { required },
        contact: { required },
      },
    };
  },
  methods: {
    submitForm () {
      if (this.validate()) return;
      this.$emit('submit');
    },
    validate () {
      this.$v.$touch();
      return this.$v.$error;
    },
    isError (field) {
      return (this.$v && this.$v.request[field]?.$error);
    },
    errorText (field) {
      let clientError = '';
      if (this.$v && this.$v.request[field] && this.$v.request[field].$params) {
        Object.keys(this.$v.request[field].$params)
          .sort((paramA, paramB) => paramA === 'required' ? -1 : paramB === 'required' ? 1 : 0)
          .every(param => {
            if (!this.$v.request[field][param]) {
              clientError = this.$t(`errors.${param}`);
              return false;
            }
            return true;
          });
      }
      return clientError;
    },
  },
};
