import { render, staticRenderFns } from "./form.vue?vue&type=template&id=4700d280"
import script from "./form.vue?vue&type=script&lang=js"
export * from "./form.vue?vue&type=script&lang=js"
import style0 from "./form.vue?vue&type=style&index=0&id=4700d280&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzTextField: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/text-field/index.vue').default,RzTextarea: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/textarea/index.vue').default,RzButton: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/button/index.js').default})
