
export default {
  name: 'product-list',
  props: {
    products: { type: Array, default: () => [] },
    withoutBg: { type: Boolean, default: false },
    cardWithImage: { type: Boolean, default: false },
    isHtml: { type: Boolean, default: false },
    isShowImage: { type: Boolean, default: false },
  },
};
