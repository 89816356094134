import { render, staticRenderFns } from "./contact-us.vue?vue&type=template&id=45ef2143"
import script from "./contact-us.vue?vue&type=script&lang=js"
export * from "./contact-us.vue?vue&type=script&lang=js"
import style0 from "./contact-us.vue?vue&type=style&index=0&id=45ef2143&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactForm: require('/opt/hkok.ru/src/components/contact/form.vue').default,BaseSection: require('/opt/hkok.ru/src/components/base/section.vue').default})
