
export default {
  name: 'base-tabs',
  props: {
    tabs: { type: Array, default: () => [] },
    defaultTab: { type: String, default: '' },
  },
  watch: {
    defaultTab: {
      handler (value) {
        if (!value) return;
        this.setDefaultTab();
      },
    },
  },
  mounted () {
    if (this.defaultTab !== '') this.setDefaultTab();
  },
  methods: {
    setDefaultTab () {
      this.$refs.tabs.activeTab = this.defaultTab;
    },
    getTabClasses (tab) {
      return {
        'base-tabs__tab--disabled': tab.disabled,
      };
    },
  },
};
