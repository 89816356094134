
export default {
  name: 'base-section',
  props: {
    mainBg: { type: Boolean, default: false },
    greyBg: { type: Boolean, default: false },
    secondBg: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    headerPadding: { type: Boolean, default: false },
    withoutPadding: { type: Boolean, default: false },
    withoutBottomPadding: { type: Boolean, default: false },
    centerContent: { type: Boolean, default: false },
  },
  computed: {
    classes () {
      return {
        'base-section--main-bg': this.mainBg,
        'base-section--grey-bg': this.greyBg,
        'base-section--second-bg': this.secondBg,
        'base-section--full-width': this.fullWidth,
        'base-section--header': this.headerPadding,
        'base-section--without-padding': this.withoutPadding,
        'base-section--center': this.centerContent,
        'base-section--without-bottom': this.withoutBottomPadding,
      };
    },
  },
};
