import { render, staticRenderFns } from "./image-modal.vue?vue&type=template&id=d0b2c1c4&scoped=true"
import script from "./image-modal.vue?vue&type=script&lang=js"
export * from "./image-modal.vue?vue&type=script&lang=js"
import style0 from "./image-modal.vue?vue&type=style&index=0&id=d0b2c1c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0b2c1c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconClose: require('/opt/hkok.ru/node_modules/razlet-ui/src/icons/icon-close/index.vue').default})
