
export default {
  name: 'base-image-modal',
  model: {
    prop: 'isOpen',
    event: 'change',
  },
  props: {
    isOpen: { type: Boolean, default: false },
    media: { type: Object, default: () => ({}) },
  },
};
