export default class FeedbackRequest {
  contact = '';
  name = '';
  message = '';

  build () {
    const {
      contact,
      name,
      message,
    } = this;

    return {
      contact,
      name,
      message,
    };
  }
};
